import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {classToPlain, plainToClass} from 'class-transformer';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import * as camelcaseKeys from 'camelcase-keys';
import {Media} from '../entities/media/media.entity';
import {BaseEntityService} from './base-entity.service';
import {holosenEntityMetadataStorage} from '../metadata/holosen-entity-metadata-storage';
import {AppConfig} from '../../app.config';
import {IHolosenHttpServiceResponse} from '../../core/services/holosen-http.service';
import {Training} from '../entities/training/training.entity';
import * as snakecaseKeys from 'snakecase-keys';
import {Activity} from '../entities/activity/activity.entity';
import {Cpa} from '../entities/cpa/cpa.entity';
import {Change} from '../entities/change/change.entity';
import {Kpi} from '../entities/kpi/kpi.entity';
import {Meeting} from '../entities/meeting/meeting.entity';
import {Risk} from '../entities/risk/risk.entity';
import {QualityObjective} from '../entities/quality-objective/quality-objective.entity';

@Injectable({
  providedIn: 'root'
})
export abstract class BasePlannableEntityService<T extends Training | Cpa | Change | Kpi | Meeting | Risk | QualityObjective> extends BaseEntityService<T> {

  public upsertAttachmentRemote = (entityID: number, attachment: Media, options?: { upload?: { file: File, name: string } }): Observable<any> => {
    const url = `${AppConfig.URL.API + holosenEntityMetadataStorage.getApiPrefix(this.STATE_MODEL.name)}/${entityID}/attachment/${attachment.id ? attachment.id + '/' : ''}`;

    const hasUpload = options && options.upload;

    const requestPayload = snakecaseKeys(classToPlain(attachment, {groups: ['toAPI']}), {deep: true});
    delete requestPayload.file;
    const formData = new FormData();
    if (hasUpload) {
      Object.keys(requestPayload).forEach((key) => {
        if (requestPayload[key]) {
          formData.append(key, requestPayload[key] as any);
        }
      });
      formData.set(options.upload.name, options.upload.file, options.upload.file.name);
      if (attachment.id) {
        formData.append('_method', 'PUT');
      }
    }

    const httpRequest = attachment.id /*&& !hasUpload*/ ? this.httpService.put : this.httpService.post;

    return httpRequest(url, hasUpload ? formData : requestPayload).pipe(
      switchMap((httpResult: IHolosenHttpServiceResponse) => {
        const convertedPayload = plainToClass(Media, camelcaseKeys(httpResult, {deep: true}), {groups: ['fromAPI']});
        console.log(`[attachment] `, attachment);
        if (attachment.id) {
          return this.stateManager.upsert(Media, convertedPayload).pipe(
            map(_ => convertedPayload.id)
          );
        } else {
          return this.selectByID(entityID).pipe(
            take(1),
            switchMap(training => {
              training.attachments.push(convertedPayload);
              return this.stateManager.upsert(this.STATE_MODEL, training).pipe(
                map(_ => convertedPayload.id)
              );
            })
          );
        }
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  };


  public deleteAttachmentRemote = (entityID: number, attachment: Media): Observable<any> => {
    const url = `${AppConfig.URL.API + holosenEntityMetadataStorage.getApiPrefix(this.STATE_MODEL.name)}/${entityID}/attachment/${attachment.id}/`;

    return this.httpService.delete(url, {}).pipe(
      switchMap((httpResult: IHolosenHttpServiceResponse) => {
        return this.selectByID(entityID).pipe(
          take(1),
          switchMap(entity => {
            entity.attachments = entity.attachments.filter(act => act.id !== attachment.id);
            return this.stateManager.upsert(this.STATE_MODEL, entity).pipe(
              switchMap(_ => this.stateManager.delete(Media, attachment.id))
            );
          })
        );
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  };


  public upsertActivityRemote = (entityID: number, activity: Activity): Observable<any> => {
    const url = `${AppConfig.URL.API + holosenEntityMetadataStorage.getApiPrefix(this.STATE_MODEL.name)}/${entityID}/activities/${activity.id ? activity.id + '/' : ''}`;

    const requestPayload = snakecaseKeys(classToPlain(activity, {groups: ['toAPI']}), {deep: true});

    const httpRequest = activity.id /*&& !hasUpload*/ ? this.httpService.put : this.httpService.post;

    return httpRequest(url, requestPayload).pipe(
      switchMap((httpResult: IHolosenHttpServiceResponse) => {
        const convertedPayload = plainToClass(Activity, camelcaseKeys(httpResult, {deep: true}), {groups: ['fromAPI']});

        if (activity.id) {
          return this.stateManager.upsert(Activity, convertedPayload).pipe(
            map(_ => convertedPayload.id)
          );
        } else {
          return this.selectByID(entityID).pipe(
            take(1),
            switchMap(training => {
              training.activities.push(convertedPayload);
              return this.stateManager.upsert(this.STATE_MODEL, training).pipe(
                map(_ => convertedPayload.id)
              );
            })
          );
        }
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  };


  public deleteActivityRemote = (entityID: number, activity: Activity): Observable<any> => {
    const url = `${AppConfig.URL.API + holosenEntityMetadataStorage.getApiPrefix(this.STATE_MODEL.name)}/${entityID}/delete-activity/${activity.id}/`;

    return this.httpService.delete(url, {}).pipe(
      switchMap((httpResult: IHolosenHttpServiceResponse) => {
        return this.selectByID(entityID).pipe(
          take(1),
          switchMap(entity => {
            entity.activities = entity.activities.filter(act => act.id !== activity.id)
            return this.stateManager.upsert(this.STATE_MODEL, entity).pipe(
              switchMap(_ => this.stateManager.delete(Activity, activity.id))
            );
          })
        );
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  };


  public cancelPlannableRemote = (entityID: number): Observable<any> => {
    const url = `${AppConfig.URL.API + holosenEntityMetadataStorage.getApiPrefix(this.STATE_MODEL.name)}/${entityID}/change-status/`;

    const requestPayload = {
      status: 5
    }

    return this.httpService.post(url, requestPayload).pipe(
      switchMap((httpResult: IHolosenHttpServiceResponse) => {
        return this.selectByID(entityID).pipe(
          take(1),
          switchMap(entity => {
            return this.stateManager.upsert(this.STATE_MODEL, entity);
          })
        );
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  };

}
