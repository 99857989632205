import {Injectable} from '@angular/core';
import {BaseEntityService} from '../../services/base-entity.service';
import {Operation} from './operation.entity';

@Injectable({
  providedIn: 'root'
})
export class OperationEntityService extends BaseEntityService<Operation> {

  protected readonly STATE_MODEL = Operation;

  constructor() {
    super();
  }


}
