import {Injectable} from '@angular/core';
import {Change} from './change.entity';
import {BasePlannableEntityService} from '../../services/base-plannable-entity.service';

@Injectable({
  providedIn: 'root'
})
export class ChangeEntityService extends BasePlannableEntityService<Change> {

  protected readonly STATE_MODEL = Change;

  constructor() {
    super();
  }

}
