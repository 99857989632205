import {Injectable} from '@angular/core';
import {Kpi} from './kpi.entity';
import {BasePlannableEntityService} from '../../services/base-plannable-entity.service';

@Injectable({
  providedIn: 'root'
})
export class KpiEntityService extends BasePlannableEntityService<Kpi> {

  protected readonly STATE_MODEL = Kpi;

  constructor() {
    super();
  }

}
