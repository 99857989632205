import {Injectable} from '@angular/core';
import {BaseEntityService} from '../../services/base-entity.service';
import {Contact} from './contact.entity';

@Injectable({
  providedIn: 'root'
})
export class ContactEntityService extends BaseEntityService<Contact> {

  protected readonly STATE_MODEL = Contact;

  constructor() {
    super();
  }



}
