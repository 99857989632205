import {Injectable} from '@angular/core';
import {Cpa} from './cpa.entity';
import {BasePlannableEntityService} from '../../services/base-plannable-entity.service';

@Injectable({
  providedIn: 'root'
})
export class CpaEntityService extends BasePlannableEntityService<Cpa> {

  protected readonly STATE_MODEL = Cpa;

  constructor() {
    super();
  }

}
