import {Injectable} from '@angular/core';
import {BaseEntityService} from '../../services/base-entity.service';
import {Address} from './address.entity';

@Injectable({
  providedIn: 'root'
})
export class AddressEntityService extends BaseEntityService<Address> {

  protected readonly STATE_MODEL = Address;

  constructor() {
    super();
  }



}
