import {Injectable} from '@angular/core';
import {BaseEntityService} from '../../services/base-entity.service';
import {Unit} from './unit.entity';

@Injectable({
  providedIn: 'root'
})
export class UnitEntityService extends BaseEntityService<Unit> {

  protected readonly STATE_MODEL = Unit;

  constructor() {
    super();
  }


}
