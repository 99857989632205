import {Injectable} from '@angular/core';
import {Training} from './training.entity';
import {BasePlannableEntityService} from '../../services/base-plannable-entity.service';

@Injectable({
  providedIn: 'root'
})
export class TrainingEntityService extends BasePlannableEntityService<Training> {

  protected readonly STATE_MODEL = Training;

  constructor() {
    super();
  }

}
