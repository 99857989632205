import {Injectable} from '@angular/core';
import {Meeting} from './meeting.entity';
import {BasePlannableEntityService} from '../../services/base-plannable-entity.service';

@Injectable({
  providedIn: 'root'
})
export class MeetingEntityService extends BasePlannableEntityService<Meeting> {

  protected readonly STATE_MODEL = Meeting;

  constructor() {
    super();
  }

}
